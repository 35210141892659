import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledButtonProps {
  bg: string;
  width?: number | string;
}

export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ bg, width }) => ({
    '&.MuiButton-root': {
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '16px',
      backgroundColor: bg,
      color: 'white',
      border: '1px solid lightgray',
      borderRadius: '6px',
      width: width ? width : '120px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
