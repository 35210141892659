import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
// import { errorMessage, successMessage } from 'ui-component/SweetAlert';
const initialState = {
  jobVideosList: [],
};

const slice = createSlice({
  name: 'jobVideos',
  initialState,
  reducers: {
    getJobVideosList(state, action) {
      state.jobVideosList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getJobVideosAction(jobId, handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `rating-criteria-users/get-rating-videos?jobId=${jobId}`
      );
      dispatch(slice.actions.getJobVideosList(response.data));
      handleLoader();
    } catch (error) {
      handleLoader();
    }
  };
}

export function uploadJobVideosAction(jobId, videoArray, handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.patch(
        `rating-criteria-users/update-rating-videos`,
        {
          links: videoArray,
          jobId: jobId,
        }
      );
      handleLoader(true, response?.data?.message);
    } catch (error) {
      handleLoader(false, error?.data?.data?.message);
    }
  };
}
