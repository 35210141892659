// material-ui
import { ButtonBase } from '@mui/material';
// project imports
import config from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { menuOpen } from 'redux/slices/CustomizationSLice';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      onClick={() => dispatch(menuOpen({ id: defaultId }))}
      component={Link}
      to={config.defaultPath}
    >
      <Logo width={100} height={50} />
    </ButtonBase>
  );
};

export default LogoSection;
