import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

const initialState = {
  companyList: [],
  isLoading: false,
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getAllCompanyList(state, action) {
      state.companyList = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanyAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllCompanyAction.fulfilled,
      (state, { payload: { data } }) => {
        state.companyList = data.data;
        state.isLoading = false;
      }
    );
    builder.addCase(getAllCompanyAction.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default slice.reducer;

export const getAllCompanyAction = createAsyncThunk(
  'companySlice/getAllCompanyAction',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`companies`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export function updateCompanyStatusAction(data, handlResponse) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`companies/?id=${data.id}`, {
        active: data.status,
      });
      successMessage(response.data.message);
      if (handlResponse) {
        handlResponse();
      }
    } catch (error) {
      console.log(error);
      errorMessage(error.response.data.message);
      if (handlResponse) {
        handlResponse();
      }
    }
  };
}

export function UpdateCompanyAction(data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`companies/?id=${data.id}`, {
        name: data.name,
        description: data.description,
      });
      successMessage(response.data.message);
      handleUpdateRes();
    } catch (error) {
      console.log(error);
      errorMessage(error.response.data.message);
      handleUpdateRes();
    }
  };
}

export function addCompanyAction(data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.post(`companies`, data);
      successMessage(response.data.message);
      handleUpdateRes(true);
    } catch (error) {
      errorMessage(error.response.data.message);
      handleUpdateRes(false);
    }
  };
}
