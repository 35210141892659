import './sweetalert.css';

import Swal from 'sweetalert2';
import swalWithBootstrapButtons from 'sweetalert2';
export const successMessage = (message) => {
  Swal.fire('Successfull!', message, 'success');
};
export const errorMessage = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: message,
  });
};

export const ConfirmationAlert = (handleConfirmationRes, message) => {
  swalWithBootstrapButtons
    .fire({
      title: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, Do it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleConfirmationRes();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
};

export const reasonConfirmationAlert = (
  message,
  handleReasonConfirmationRes
) => {
  swalWithBootstrapButtons.fire({
    title: message,
    input: 'select',
    inputOptions: {
      'Job information is invalid': 'Job information is invalid',
      'No need for this job anymore': 'No need for this job anymore',
      'Vehicle already sold': 'Vehicle already sold',
      Other: 'Other',
    },
    inputPlaceholder: 'Select a Reason',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, do it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-success',
    },
    buttonsStyling: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          if (value === 'Other') {
            Swal.fire({
              title: 'Add reason',
              input: 'text',
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel!',
              customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-success',
              },
              reverseButtons: true,
              showCancelButton: true,
              inputValidator: (value) => {
                if (value) {
                  handleReasonConfirmationRes(value);
                } else {
                  return 'You need to write something!';
                }
              },
            });
          } else {
            resolve();
            handleReasonConfirmationRes(value);
          }
        } else {
          resolve('Please select one reason');
        }
      });
    },
  });
};

export const deleteImageAlert = (handleimageDelete, indexOne, indexTwo) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Do you really want to delete this image ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleimageDelete(indexOne, indexTwo);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
};

export const deleteVideoAlert = (handleDeleteVideosRes, index) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Do you really want to delete this Video ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleDeleteVideosRes(index);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
    });
};
