import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import adminReducer from './slices/AdminSlice';
import categoryReducer from './slices/CategorySlice';
import chatReducer from './slices/ChatSlice';
import companyReducer from './slices/CompanySlice';
import costReducer from './slices/CostSlice';
import couponReducer from './slices/CouponSlice';
import criteriaOptionReducer from './slices/CriteriaOptionSlice';
import criteriasReducer from './slices/CriteriasSlice';
import customizationReducer from './slices/CustomizationSLice';
import damagesReducer from './slices/DamagesSlice';
import discountReducer from './slices/DiscountSlice';
import jobsListReducer from './slices/JobsListSlice';
import jobVideosReducer from './slices/JobVideosSlice';
import loginSlice from './slices/LoginSlice';
import pdfReducer from './slices/PdfSlice';
import permissionReducer from './slices/PermissionSlice';
import r8trListReducer from './slices/R8trListSlice';
import reportsReducer from './slices/ReportsSlice';
import rolesReducer from './slices/RolesSlice';
import specialNotesReducer from './slices/SpecialNotesSlice';
import userListReducer from './slices/UserListSlice';
// ==============================|| COMBINE REDUCER ||============================== //

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['loginSlice'],
};
const rootReducer = combineReducers({
  customization: customizationReducer,
  r8trListReducer,
  jobsListReducer,
  companyReducer,
  userListReducer,
  criteriasReducer,
  damagesReducer,
  jobVideosReducer,
  specialNotesReducer,
  pdfReducer,
  categoryReducer,
  costReducer,
  chatReducer,
  adminReducer,
  couponReducer,
  discountReducer,
  criteriaOptionReducer,
  rolesReducer,
  permissionReducer,
  reportsReducer,
  loginSlice,
});

export type RootReducerType = ReturnType<typeof rootReducer>;

export { rootPersistConfig, rootReducer };
