// project imports
// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { menuOpen, setMenu } from 'redux/slices/CustomizationSLice';
// import { useState } from 'react';
// import socketServcies from 'socketService';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const user = JSON.parse(window.localStorage.getItem('user'));
  const customization = useSelector((state) => state.customization);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const Icon = item.icon;
  // const [messageCounter, setMessageCounter] = useState(false);
  // const [haveChatNotifications, setHaveChatNotifications] = useState(false);
  // useEffect(() => {
  //   if (socketServcies) {
  //     socketServcies.on('admin message received', (data) => {
  //       console.log(data);
  //       if (location.pathname !== '/chat') {
  //         setMessageCounter(true);
  //       }
  //     });
  //   }
  // }, [socketServcies]);
  // useEffect(() => {
  //   if (location.pathname !== '/chat') {
  //     setHaveChatNotifications(user.haveChatNotifications);
  //   }
  // }, [user]);
  // const handleMessageCounter = (path) => {
  //   if (path === '/chat') {
  //     let userObj = { ...user };
  //     setMessageCounter(null);
  //     if (userObj.haveChatNotifications) {
  //       userObj.haveChatNotifications = false;
  //       localStorage.setItem('user', JSON.stringify(userObj));
  //       setHaveChatNotifications(null);
  //     }
  //   }
  // };
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height:
          customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    // handleMessageCounter();
    dispatch(menuOpen({ id }));
    if (matchesSM) dispatch(setMenu({ opened: false }));
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(menuOpen({ id: item.id }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${customization.borderRadius}px`,
        mb: 0.5,
        // alignItems: 'flex-start',
        display: 'flex',
        alignItems: 'center',
        // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
      }}
      selected={customization.isOpen[0]?.id === item.id}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={
              customization.isOpen.findIndex((id) => id === item.id) > -1
                ? 'h5'
                : 'body1'
            }
            color="inherit"
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {/* {item.chip && (messageCounter || haveChatNotifications) && (
        <Badge color="secondary" variant="dot">
          {item.chip.label}
        </Badge>
      )} */}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
