import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
// import { errorMessage, successMessage } from 'ui-component/SweetAlert';
const initialState = {
  jobSpecialNotes: {},
};

const slice = createSlice({
  name: 'jobVideos',
  initialState,
  reducers: {
    getJobSpecialNotes(state, action) {
      state.jobSpecialNotes = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getJobSpecialNotesAction(jobId) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `rating-special-messages/?jobId=${jobId}`
      );
      dispatch(slice.actions.getJobSpecialNotes(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}
