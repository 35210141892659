export type SubCategory = {
  id: number;
  name: string;
  active: boolean;
  categoryId: number;
};

export enum CategoryType {
  UNKNOWN = 'UNKNOWN',
  COMMERCIAL = 'COMMERCIAL',
  COMPANY = 'COMPANY',
  AMAZON_PAVE = 'AMAZON_PAVE',
}

export type Category = {
  id: number;
  name: string;
  active: boolean;
  color: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  forApp: boolean;
  icon: string;
  includeWalkAroundVideos: boolean;
  type?: CategoryType;
  subCategories: SubCategory[];
};
