import { Avatar, Box, ButtonBase } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// import SearchSection from './SearchSection';
// import ProfileSection from './ProfileSection';
// import NotificationSection from './NotificationSection';
// assets
import { IconMenu2 } from '@tabler/icons';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setIsLoggedIn } from 'redux/slices/LoginSlice';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { StyledButton } from 'ui-component/extended/ButtonComponent';

// project imports
import LogoSection from '../LogoSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const router = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(setIsLoggedIn(false));
    localStorage.clear();
    router('/');
  }, []);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto',
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{ borderRadius: '12px', overflow: 'hidden' }}
          onClick={handleLeftDrawerToggle}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* <NotificationSection />
      <ProfileSection /> */}
      <AnimateButton>
        <StyledButton bg="#FF0000" onClick={handleLogout}>
          Logout
        </StyledButton>
      </AnimateButton>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
