import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';

import AuthenticationRoutes from './AuthenticationRoutes';
// routes
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  const { isLoggedIn } = useSelector((state) => state.loginSlice);

  const routes =
    isLoggedIn && tokens?.accessToken ? MainRoutes : AuthenticationRoutes;
  return useRoutes([routes]);
}
