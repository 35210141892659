import axios from 'axios';
import { setIsLoggedIn } from 'redux/slices/LoginSlice';
import { store } from 'redux/Store';

import constants from './constant';

export const api = axios.create({
  baseURL: constants.API_SERVER_BASE_URL,
});

const axiosInstance = axios.create({
  baseURL: constants.API_SERVER_BASE_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const fetchRefreshToken = async (refreshToken) => {
  const { data } = await api.post('auth/refresh-token', { refreshToken });

  localStorage.setItem(
    'tokens',
    JSON.stringify({
      accessToken: data?.token,
      refreshToken: data?.refreshToken,
    })
  );

  return data;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    if (tokens?.accessToken) {
      config.headers['Authorization'] = `Bearer ${tokens?.accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const tokens = JSON.parse(localStorage.getItem('tokens'));
      if (!tokens?.refreshToken) {
        store.dispatch(setIsLoggedIn(false));
        return Promise.reject(error);
      }

      return new Promise((resolve, reject) => {
        fetchRefreshToken(tokens.refreshToken)
          .then((newTokens) => {
            axiosInstance.defaults.headers.common['Authorization'] =
              `Bearer ${newTokens.token}`;
            originalRequest.headers['Authorization'] =
              `Bearer ${newTokens.token}`;
            processQueue(null, newTokens.token);
            resolve(axiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            store.dispatch(setIsLoggedIn(false));
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
