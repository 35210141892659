import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';
import { Job } from 'views/jobs/types';

import { AppThunk } from '../Store';

interface JobState {
  jobsList: Job[];
  jobData: Job | undefined;
  commercialJobs: Job[];
  companyJobs: Job[];
  amazonPaveJobs: Job[];
  loading: boolean;
  error: string | null;
}

const initialState: JobState = {
  jobsList: [],
  jobData: undefined,
  commercialJobs: [],
  companyJobs: [],
  amazonPaveJobs: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    getAllJobsList(state, action: PayloadAction<{ data: Job[] }>) {
      state.jobsList = action.payload.data;
    },
    getJobsDetail(state, action: PayloadAction<{ data: Job }>) {
      state.jobData = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCommercialJobsAction.fulfilled, (state, action) => {
      state.commercialJobs = action.payload.data.data;
      state.loading = false;
    });
    builder.addCase(
      getAllCommercialJobsAction.rejected,
      (state, { payload }) => {
        state.loading = false;
        //@ts-ignore
        state.error = payload?.message || 'Something went wrong';
      }
    );
    builder.addCase(getAllCommercialJobsAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCompanyJobsAction.fulfilled, (state, action) => {
      state.companyJobs = action.payload.data.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllCompanyJobsAction.rejected, (state, { payload }) => {
      state.loading = false;
      //@ts-ignore
      state.error = payload?.message || 'Something went wrong';
    });
    builder.addCase(getAllCompanyJobsAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllAmazonPaveJobsAction.fulfilled, (state, action) => {
      state.amazonPaveJobs = action.payload.data.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(
      getAllAmazonPaveJobsAction.rejected,
      (state, { payload }) => {
        state.loading = false;
        //@ts-ignore
        state.error = payload?.message || 'Something went wrong';
      }
    );
    builder.addCase(getAllAmazonPaveJobsAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(uploadPaveBulkJobAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(uploadPaveBulkJobAction.rejected, (state, { payload }) => {
      state.loading = false;
      //@ts-ignore
      state.error = payload?.message || 'Something went wrong';
    });
    builder.addCase(uploadPaveBulkJobAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(listingJobsUnassignAction.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(
      listingJobsUnassignAction.rejected,
      (state, { payload }) => {
        state.loading = false;
        //@ts-ignore
        state.error = payload?.message || 'Something went wrong';
      }
    );
    builder.addCase(listingJobsUnassignAction.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
  },
});

export default slice.reducer;

export const getAllCompanyJobsAction = createAsyncThunk(
  'job/getAllCompanyJobsAction',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/jobs/company-jobs`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllAmazonPaveJobsAction = createAsyncThunk(
  'job/getAllAmazonPaveJobsAction',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/jobs/amazon-pave-jobs`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getAllCommercialJobsAction = createAsyncThunk(
  'job/getAllCommercialJobsAction',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/jobs/commercial-jobs`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export function getAllJobsAction(): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(
        `/jobs/all-jobs-v2?page=0&limit=50000`
      );
      dispatch(slice.actions.getAllJobsList(response.data));
      // handleLoader();
    } catch (error) {
      // handleLoader();
    }
  };
}

export function getJobsDetailAction(
  id: number,
  handleResponse?: () => void
): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`jobs/get-job-details?id=${id}`);
      dispatch(slice.actions.getJobsDetail(response.data));
      if (handleResponse) {
        handleResponse();
      }
    } catch (error: any) {
      errorMessage(error?.response?.data?.message);
      if (handleResponse) {
        handleResponse();
      }
    }
  };
}

export function jobAssignAction(
  handleClose: () => void,
  r8trId: number,
  jobIds: number[],
  mode: 'assign' | 'reassign'
): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(`jobs/job-assigned-by-admin`, {
        r8trId,
        jobIds,
        mode,
      });
      if (jobIds.length === 1) {
        dispatch(getJobsDetailAction(jobIds[0]));
      }
      successMessage(response?.data?.message);
      handleClose();
    } catch (error: any) {
      handleClose();
      errorMessage(error.response.data.message);
    }
  };
}

export function jobUnassignAction(
  id: number,
  handleUnAssignRes?: () => void
): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch('jobs/job-unassign-by-admin', {
        jobIds: [id],
      });
      dispatch(getJobsDetailAction(id));
      if (handleUnAssignRes) {
        handleUnAssignRes();
      }
      successMessage(response?.data?.message);
    } catch (error: any) {
      errorMessage(error?.response?.data?.message);
    }
  };
}

export const listingJobsUnassignAction = createAsyncThunk(
  'job/jobsUnassignAction',
  async (
    { jobIds, onSuccess }: { jobIds: number[]; onSuccess: () => void },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.patch('jobs/job-unassign-by-admin', {
        jobIds,
      });
      onSuccess();
      successMessage(response?.data?.message);
      return response;
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export function deleteJobAction(
  jobId: number,
  reason: string,
  navigate: (path: string | number) => void
): AppThunk {
  return async () => {
    try {
      const response = await axiosInstance.patch(
        `jobs/cancel-accepted-job-by-admin?jobId=${jobId}`,
        {
          deletionOrCancellationReason: reason,
        }
      );
      successMessage(response?.data?.message);
      navigate(-1);
    } catch (error: any) {
      errorMessage(error?.response?.data?.message);
    }
  };
}

export function addJobAction(
  data: Partial<Job>,
  navigate: (path: string | number) => void,
  handleLoading: () => void
): AppThunk {
  return async () => {
    try {
      const response = await axiosInstance.post(
        `jobs/create-job-by-admin`,
        data
      );
      successMessage(response?.data?.message);
      handleLoading();
      navigate(-1);
    } catch (error: any) {
      handleLoading();
      errorMessage(error?.response?.data?.message);
    }
  };
}

export function uploadBulkJobAction(
  data: Job[],
  handleLoading: () => void
): AppThunk {
  return async () => {
    try {
      const response = await axiosInstance.post(
        `jobs/create-bulk-jobs-by-admin`,
        data
      );
      successMessage(response?.data?.message);
      handleLoading();
    } catch (error: any) {
      handleLoading();
      errorMessage(error?.response?.data?.message);
    }
  };
}

export const uploadPaveBulkJobAction = createAsyncThunk(
  'job/uploadPaveBulkJobAction',
  async (
    { data, onSuccess }: { data: Job[]; onSuccess: () => void },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.post(
        `jobs/create-pave-bulk-jobs-by-admin`,
        {
          bulkJobs: data,
        }
      );
      onSuccess();
      successMessage(response?.data?.message);
      return response;
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export function editJobAction(
  handleClose: () => void,
  data: Job,
  jobId: number
): AppThunk {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.patch(
        `jobs/job-edit-by-admin?jobId=${jobId}`,
        data
      );
      dispatch(getJobsDetailAction(jobId));
      successMessage(response?.data?.message);
      handleClose();
    } catch (error: any) {
      handleClose();
      errorMessage(error.response.data.message);
    }
  };
}
