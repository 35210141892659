import io from 'socket.io-client';
const SOCKET_URL =
  window.location.origin === 'https://admin.r8trapp.com' ||
  window.location.origin === 'https://stgadmin.r8trapp.com'
    ? 'https://api.r8trapp.com/'
    : 'https://devapi.r8trapp.com/';
class WSService {
  initializeSocket = async () => {
    try {
      this.socket = io(SOCKET_URL, {
        transports: ['websocket'],
        jsonp: false,
      });
      console.log('initializing socket', this.socket);
      this.socket.connect();
      this.socket.on('connect', () => {
        console.log('=== socket connected ====');
      });
      this.socket.on('disconnect', () => {
        console.log('=== socket disconnected ====');
      });
      this.socket.on('error', (data) => {
        console.log('socekt error', data);
      });
    } catch (error) {
      console.log('scoket is not inialized', error);
    }
  };
  emit(event, data = {}) {
    this.socket.emit(event, data);
  }
  on(event, cb) {
    this.socket.on(event, cb);
  }
  removeListener(listenerName) {
    this.socket.removeListener(listenerName);
  }
}
const socketServcies = new WSService();
export default socketServcies;
