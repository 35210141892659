// style + assets
import 'assets/scss/style.scss';

import App from 'App';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// third party
import { BrowserRouter } from 'react-router-dom';
import { store } from 'redux/Store';
// project imports
import * as serviceWorker from 'serviceWorker';
// import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //

// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(
//   <Provider store={store}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>
// );
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
