import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { Category, CategoryType } from 'models/category';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch, RootState } from '../Store';

type CategorySliceData = {
  categoryList: Category[];
  bulkJobsCategoryList: any[];
  error: string | null;
  loading: boolean;
};

const initialState: CategorySliceData = {
  categoryList: [],
  bulkJobsCategoryList: [],
  error: null,
  loading: false,
};

const slice = createSlice({
  name: 'Category',
  initialState,
  reducers: {
    getAllCategoryList(state, action) {
      state.categoryList = action.payload.data;
    },
    getBulkJobsCategoryList(state, action) {
      state.bulkJobsCategoryList = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCategoryAction.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllCategoryAction.fulfilled, (state, action) => {
      state.categoryList = action.payload.data.data;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAllCategoryAction.rejected, (state, { payload }) => {
      state.loading = false;
      //@ts-ignore TODO: Fix this type error
      state.error = payload?.message || 'Something went wrong';
    });
  },
});

export const selectCategoryList = (state: RootState) =>
  state.categoryReducer.categoryList;

// Remove fleet van category from category list because only company job will create by fleet van category - some old comment, i just moved it to the selector
export const selectActiveCommercialCategoryList = createSelector(
  [selectCategoryList],
  (categoryList) =>
    categoryList.filter(
      (category) =>
        category.id !== 8 &&
        category.active &&
        category.type !== CategoryType.AMAZON_PAVE
    )
);

export const selectPaveCategoryList = createSelector(
  [selectCategoryList],
  (categoryList) =>
    categoryList.filter(
      (category) =>
        category.active && category.type === CategoryType.AMAZON_PAVE
    )
);

export default slice.reducer;
// categoryList

export const getAllCategoryAction = createAsyncThunk(
  'categorySlice/getAllCompanyAction',
  async (categoryType: CategoryType, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `sub-categories/categories-and-sub-categories`,
        { params: { page: 0, limit: 10, categoryType } }
      );
      return response;
    } catch (error) {
      errorMessage(error.response.data?.message || 'Something went wrong');
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// export function getAllCategoryAction(categoryType: CategoryType) {
//   return async () => {
//     try {
// const response = await axiosInstance.get(
//   `sub-categories/categories-and-sub-categories`,
//   { params: { page: 0, limit: 10, categoryType } }
// );
//       dispatch(slice.actions.getAllCategoryList(response.data));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// }
export function getBulkJobsCategoryAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `sub-categories/categories-and-sub-categories-for-bulk-jobs`
      );
      dispatch(slice.actions.getBulkJobsCategoryList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCategoryStatusAction(id, active, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`categories/?id=${id}`, {
        active,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function updateCategoryAction(id, paramsData, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`categories/?id=${id}`, {
        name: paramsData.name,
        color: paramsData.color,
        active: paramsData.isActive,
        forApp: paramsData.forApp,
        icon: paramsData.icon,
        includeWalkAroundVideos: paramsData.includeWalkAroundVideos,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function addCategoryAction(
  data,
  imageFile,
  forApp,
  includeWalkAroundVideos,
  cost,
  ratingCriterias,
  handleClose
) {
  return async () => {
    try {
      const response = await axiosInstance.post(`categories/`, {
        name: data.categoryname,
        color: data.color,
        icon: imageFile,
        forApp: forApp,
        includeWalkAroundVideos: includeWalkAroundVideos,
        cost,
        ratingCriterias,
      });
      successMessage(response?.data?.message);
      handleClose(true);
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose(false);
    }
  };
}
