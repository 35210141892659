import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  existingChatList: [],
  newUserList: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getAllExistisgChatList(state, action) {
      state.existingChatList = action.payload.data;
    },
    updateAllExistingChatList(state, action) {
      state.existingChatList = action.payload;
    },
    getAllNewUserList(state, action) {
      state.newUserList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllExistisgChatAction(handleLoading) {
  return async () => {
    try {
      const response = await axiosInstance.get(`admins/get-chats`);
      dispatch(slice.actions.getAllExistisgChatList(response.data));
      if (handleLoading) {
        handleLoading();
      }
    } catch (error) {
      console.log(error);
      if (handleLoading) {
        handleLoading();
      }
    }
  };
}

export function updateAllExistingChatAction(data) {
  return async () => {
    try {
      dispatch(slice.actions.updateAllExistingChatList(data));
    } catch (error) {
      console.log(error);
    }
  };
}
export function getAllNewUserAction(handleLoading) {
  return async () => {
    try {
      const response = await axiosInstance.get(`admins/users-for-chat`);
      dispatch(slice.actions.getAllNewUserList(response.data));
      handleLoading();
    } catch (error) {
      console.log(error);
      if (handleLoading) {
        handleLoading();
      }
    }
  };
}
