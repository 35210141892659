import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
// import { errorMessage, successMessage } from 'ui-component/SweetAlert';
const initialState = {
  simplePdfData: {},
  comapnyPdfData: {},
};

const slice = createSlice({
  name: 'jobPdf',
  initialState,
  reducers: {
    getSimplePdfData(state, action) {
      state.simplePdfData = action.payload.data;
    },
    getCompanyPdfData(state, action) {
      state.comapnyPdfData = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getSimplePdfAction(
  jobId,
  handleSimpleJobData,
  handlePdfLoading
) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `jobs/get-job-details-for-pdf-for-non-company-jobs?id=${jobId}`
      );
      response.data.data?.ratingCriterias?.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );

      dispatch(slice.actions.getSimplePdfData(response.data));
      if (handlePdfLoading) {
        handlePdfLoading();
      }
      if (handleSimpleJobData) {
        handleSimpleJobData();
      }
    } catch (error) {
      if (handlePdfLoading) {
        handlePdfLoading();
      }
      console.log(error);
    }
  };
}

export function getCompanyPdfAction(
  jobId,
  handleSimpleJobData,
  handlePdfLoading
) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `jobs/get-job-details-with-ratings-v2?id=${jobId}`
      );
      dispatch(slice.actions.getCompanyPdfData(response.data));
      if (handlePdfLoading) {
        handlePdfLoading();
      }
      if (handleSimpleJobData) {
        handleSimpleJobData();
      }
    } catch (error) {
      if (handlePdfLoading) {
        handlePdfLoading();
      }
      console.log(error);
    }
  };
}

export function uploadPdfAction(jobId, pdfLink, handleUploadPdfRes) {
  return async () => {
    try {
      await axiosInstance.patch(`jobs/add-job-pdf-link`, {
        jobId,
        pdfLink,
      });
      await axiosInstance.patch(
        `jobs/reset-reviewed-jobs-for-csv-in-db?jobId=${jobId}`
      );
      if (handleUploadPdfRes) {
        handleUploadPdfRes();
      }
    } catch (error) {
      console.log(error);
    }
  };
}
