import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  reviewedJobsList: [],
};

const slice = createSlice({
  name: 'Reports',
  initialState,
  reducers: {
    getReviewedJobsList(state, action) {
      state.reviewedJobsList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getReviewedJobsAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `jobs/get-reviewed-jobs-by-admin`
      );
      dispatch(slice.actions.getReviewedJobsList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateJobsReportsStatusAction(data, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`jobs/admin-payout-to-r8tr`, {
        data,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

// export function updateCostAction(id, paramsData, handleClose) {
//   return async () => {
//     try {
//       const response = await axiosInstance.patch(`costs/?id=${id}`, {
//         multiplier: paramsData.multiplier,
//         minimumCost: paramsData.minimumCost,
//         priceRangeLow: paramsData.priceRangeLow,
//         priceRangeHigh: paramsData.priceRangeHigh
//       });
//       successMessage(response?.data?.message);
//       handleClose();
//     } catch (error) {
//       errorMessage(error?.response?.data?.message);
//       handleClose();
//     }
//   };
// }
