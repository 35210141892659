import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  r8trSortedList: [],
  r8trList: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getR8trList(state, action) {
      state.r8trSortedList = action.payload.data;
    },
    getUnSortedR8trList(state, action) {
      state.r8trList = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getR8trSortedList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getR8trSortedList.fulfilled, (state, action) => {
      state.loading = false;
      state.r8trSortedList = action.payload.data.data;
      state.error = null;
    });
    builder.addCase(getR8trSortedList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default slice.reducer;

export const getAllCompanyJobsAction = createAsyncThunk(
  'job/getAllCompanyJobsAction',
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/jobs/company-jobs`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getR8trSortedList = createAsyncThunk(
  'job/getR8trSortedList',
  async (
    { lat, lng, active }: { lat: number; lng: number; active: number },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.get(
        `jobs/get-r8trs-by-distance?latitude=${lat}&longitude=${lng}&active=${active}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export function updateR8trStatus(id, paramsStatus) {
  return async () => {
    try {
      const data = {
        active: paramsStatus,
      };
      const response = await axiosInstance.patch(
        `users/update-user?id=${id}`,
        data
      );
      successMessage(response?.data?.message);
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
}

export function R8trListAction(handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `dashboards/get-all-r8trs-by-admin`
      );
      dispatch(slice.actions.getUnSortedR8trList(response.data));
      handleLoader();
    } catch (error) {
      errorMessage(error.response.data.message);
      handleLoader();
    }
  };
}
