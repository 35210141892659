import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from '../Store';
const initialState = {
  allPermissions: [],
  allRoles: [],
  allRolesWithPermission: [],
};

const slice = createSlice({
  name: 'Permissions',
  initialState,
  reducers: {
    getAllPermissionsList(state, action) {
      state.allPermissions = action.payload;
    },
    setRolesList(state, action) {
      state.allRoles = action.payload;
    },
    setRolesWithPermissionsList(state, action) {
      state.allRolesWithPermission = action.payload;
    },
  },
});

export default slice.reducer;

export function getAllPermissionsAction(data) {
  return async () => {
    try {
      dispatch(slice.actions.getAllPermissionsList(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setRolesAction(data) {
  return async () => {
    try {
      dispatch(slice.actions.setRolesList(data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function setRolesWithPermissionsAction(data) {
  return async () => {
    try {
      dispatch(slice.actions.setRolesWithPermissionsList(data));
    } catch (error) {
      console.log(error);
    }
  };
}
