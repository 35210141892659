import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  criteriaOptionList: [],
};

const slice = createSlice({
  name: 'Criteria Option',
  initialState,
  reducers: {
    getCriteriaOptionList(state, action) {
      state.criteriaOptionList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllCriteriaOptionAction(handleLoading) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `rating-criteria-options/?page=0&limit=10`
      );
      dispatch(slice.actions.getCriteriaOptionList(response.data));
      if (handleLoading) {
        handleLoading();
      }
    } catch (error) {
      console.log(error);
      if (handleLoading) {
        // let errordisplay = error?.response?.data?.message || 'Something went wrong';
        // errorMessage(errordisplay);
        handleLoading();
      }
    }
  };
}

export function updateCriteriaOptionOrderAction(data) {
  return async () => {
    try {
      await axiosInstance.patch(`rating-criteria-options/update-priorities`, {
        ratingCriteriaOptions: data,
      });
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
}

export function AddCriteriaOptionAction(data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.post(
        `rating-criteria-options/`,
        data
      );
      successMessage(response?.data?.message);
      handleUpdateRes(true);
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleUpdateRes(false);
    }
  };
}

export function updateCriteriaOptionAction(id, data, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(
        `rating-criteria-options/?id=${id}`,
        data
      );
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function updateCriteriaOptionStatusAction(id, status, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(
        `rating-criteria-options/?id=${id}`,
        {
          active: status,
        }
      );
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function deleteCriteriaOptionAction(id, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.delete(
        `rating-criteria-options/?id=${id}`
      );
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}
