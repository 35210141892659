import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  rolesList: [],
};

const slice = createSlice({
  name: 'Roles',
  initialState,
  reducers: {
    getAllRolesList(state, action) {
      state.rolesList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllRolesAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`roles/`);
      dispatch(slice.actions.getAllRolesList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateRoleAction(roleId, name, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`roles/`, {
        roleId,
        name,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function addRoleAction(name, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.post(`roles/`, {
        name,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}
export function deleteRoleAction(role_id, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.delete(`roles?roleId=${role_id}`);
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function AdminRolesDeleteConfirmationAction(id, handleResqestRes) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `roles/user-exists-against-role?roleId=${id}`
      );
      handleResqestRes(response.data.data);
    } catch (error) {
      handleResqestRes();
    }
  };
}
