import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  adminsList: [],
  adminsRolesList: [],
};

const slice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    getAllAdminList(state, action) {
      state.adminsList = action.payload.data;
    },
    getAdminRolesList(state, action) {
      state.adminsRolesList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllAdminAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`admins`);
      dispatch(slice.actions.getAllAdminList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateAdminStatusAction(id, status) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`users/update-user?id=${id}`, {
        active: status,
      });
      successMessage(response.data.message);
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };
}

export function UpdateAdminAction(id, data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`admins/`, {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        userId: id,
      });
      await axiosInstance.patch(`dashboards/upload-user-image`, {
        userId: id,
        url: data.image,
      });
      successMessage(response?.data?.message);
      handleUpdateRes();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleUpdateRes();
    }
  };
}

export function getAdminRolesAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`roles/`);
      dispatch(slice.actions.getAdminRolesList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function AddAdminAction(data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.post(`admins/`, data);
      await axiosInstance.patch(`dashboards/upload-user-image`, {
        userId: response?.data?.data?.id,
        url: data.image,
      });
      successMessage('User added successfully');
      handleUpdateRes(true);
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleUpdateRes(false);
    }
  };
}
