import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  couponList: [],
  existingCouponList: [],
};

const slice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    getAllCouponList(state, action) {
      state.couponList = action.payload.data;
    },
    getExistingCouponList(state, action) {
      state.existingCouponList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getCouponsListAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`coupons/get-all-coupons`);
      dispatch(slice.actions.getAllCouponList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function existingCouponListAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`coupons/get-all-coupon-codes`);
      dispatch(slice.actions.getExistingCouponList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCouponStatusAction(id, status, handleUpdateStatus) {
  return async () => {
    try {
      const response = await axiosInstance.patch(
        `coupons/update-coupon?id=${id}`,
        {
          status,
        }
      );
      handleUpdateStatus();
      successMessage(response.data.message);
    } catch (error) {
      handleUpdateStatus();
      errorMessage(error.response.data.message);
    }
  };
}

export function addCouponAction(data, handleUpdateRes) {
  return async () => {
    try {
      const response = await axiosInstance.post(`coupons/add-coupon`, data);
      successMessage(response.data.message);
      handleUpdateRes(true);
    } catch (error) {
      errorMessage(error.response.data.message);
      handleUpdateRes(false);
    }
  };
}
