import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
// import { errorMessage, successMessage } from 'ui-component/SweetAlert';
const initialState = {
  criteriaDamagesList: [],
  allDamagesList: [],
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getCriteriaDamagesList(state, action) {
      state.criteriaDamagesList = action.payload.data;
    },
    getAllDamagesList(state, action) {
      state.allDamagesList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getCriteriaDamagesAction(criteriaId, jobId, handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `rating-criterias/get-rating-criteria-damages?id=${criteriaId}&jobId=${jobId}`
      );
      dispatch(slice.actions.getCriteriaDamagesList(response.data));
      handleLoader();
    } catch (error) {
      handleLoader();
    }
  };
}

export function getAllDamagesAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`damages/?page=0&limit=10`);
      dispatch(slice.actions.getAllDamagesList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateDamagesAction(id, title, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`damages/?id=${id}`, {
        title,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}
export function updateDamagesStatusAction(id, active, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`damages/?id=${id}`, {
        active,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function addDamagesAction(title, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.post(`damages/`, {
        title,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}
