import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  costList: [],
};

const slice = createSlice({
  name: 'Cost',
  initialState,
  reducers: {
    getAllCostList(state, action) {
      state.costList = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getAllCostAction() {
  return async () => {
    try {
      const response = await axiosInstance.get(`costs/?page=0&limit=10`);
      dispatch(slice.actions.getAllCostList(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateCostStatusAction(id, active, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`costs/?id=${id}`, {
        active,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function updateCostAction(id, paramsData, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`costs/?id=${id}`, {
        multiplier: paramsData.multiplier,
        minimumCost: paramsData.minimumCost,
        priceRangeLow: paramsData.priceRangeLow,
        priceRangeHigh: paramsData.priceRangeHigh,
      });
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}
