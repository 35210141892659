import { createSlice } from '@reduxjs/toolkit';
import { errorMessage, successMessage } from 'ui-component/SweetAlert';
import axiosInstance from 'utils/axiosInstance';

import { dispatch } from '../Store';
const initialState = {
  userData: {},
  userList: [],
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    getSingleUser(state, action) {
      state.userData = action.payload.data;
    },
    getAllUserList(state, action) {
      state.userList = action?.payload?.data;
    },
    updateUserList(state, action) {
      state.userList = action?.payload;
    },
  },
});

export default slice.reducer;

export function getSingleUserAction(id, handleLoader) {
  return async () => {
    try {
      const response = await axiosInstance.get(
        `dashboards/get-user-details/${id}`
      );
      dispatch(slice.actions.getSingleUser(response.data));
      handleLoader();
    } catch (error) {
      handleLoader();
    }
  };
}

export function getAllUserAction(handleLoading) {
  return async () => {
    try {
      const response = await axiosInstance.get(`dashboards/get-all-users-v2`);
      dispatch(slice.actions.getAllUserList(response?.data));
      if (handleLoading) {
        handleLoading();
      }
    } catch (error) {
      console.log(error);
      if (handleLoading) {
        // let errordisplay = error?.response?.data?.message || 'Something went wrong';
        // errorMessage(errordisplay);
        handleLoading();
      }
    }
  };
}

export function updateUserStatus(id, paramsStatus, handleApiResponse) {
  return async () => {
    try {
      const response = await axiosInstance.patch(`users/update-user?id=${id}`, {
        active: paramsStatus,
      });
      successMessage(response?.data?.message);
      if (handleApiResponse) {
        handleApiResponse();
      }
    } catch (error) {
      if (handleApiResponse) {
        handleApiResponse();
      }
      errorMessage(error?.response?.data?.message);
    }
  };
}

export function updateUserAction(data, handleClose) {
  return async () => {
    try {
      const response = await axiosInstance.patch(
        `users/update-user-info`,
        data
      );
      successMessage(response?.data?.message);
      handleClose();
    } catch (error) {
      errorMessage(error?.response?.data?.message);
      handleClose();
    }
  };
}

export function updateUserMessageCounterAction(data) {
  return async () => {
    try {
      dispatch(slice.actions.updateUserList(data));
    } catch (error) {
      errorMessage(error?.response?.data?.message);
    }
  };
}
